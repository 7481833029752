import * as easings from "d3-ease";

import { PoseableBox, PoseableButton } from "components/poseable";
import React, { PureComponent } from "react";
import { Box, Text } from "rebass";

import VisuallyHidden from "@reach/visually-hidden";
import Tent from "components/icons/Tent";
import TextLogo from "components/icons/TextLogo";
import GatsbyLink from "gatsby-link";
import PropTypes from "prop-types";
import posed from "react-pose";
import styled from "styled-components";
import cssForBreakpoints from "utils/cssForBreakpoints";
import events from "utils/events";
import GlobalEmitter from "utils/GlobalEmitter";

const navItems = [
  { label: "Agency", url: "/agency" },
  { label: "Work", url: "/work" },
  { label: "Contact", url: "/contact" },
];

const navItemPoseProps = {
  showing: {
    opacity: 1,
    x: 0,
    transition: {
      opacity: {
        type: "tween",
        ease: easings.easeSinOut,
        duration: 200,
        delay: 600,
      },
      x: {
        delay: 599,
        duration: 1,
        type: "tween",
      },
    },
  },
  hidden: {
    opacity: 0,
    x: 10000,
    transition: {
      opacity: {
        type: "tween",
        ease: easings.easeSinIn,
        duration: 200,
      },
      x: {
        delay: 201,
        duration: 1,
        type: "tween",
      },
    },
  },
};

const LogoLink = styled.div`
  position: fixed;
  z-index: 0;
  display: block;
  text-decoration: none;
  z-index: 1001;

  ${({ t, l }) =>
    `${cssForBreakpoints("top", t)}${cssForBreakpoints("left", l)}`}

  & a {
    position: relative;
    display: block;
    width: 175px;
    text-decoration: none;
    color: ${(props) => props.color};
    font-family: inherit;
    font-size: inherit;
    will-change: color;
    transition: color 0.2s ease-in;

    :focus {
      outline: none;
    }

    :hover,
    :focus {
      outline: none;
      color: ${(props) => props.theme.fgColor};
      transition: color 0.2s ease-out;
    }

    span,
    i {
      position: absolute;
      left: 0;
    }

    span {
      top: 0;
    }
  }
`;

const PosedNavLink = styled(posed.div(navItemPoseProps))`
  position: relative;
  display: inline-block;
  opacity: 0;
  transform: translateX(10000) translateZ(0);

  & a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    line-height: 1;

    :hover,
    :focus {
      outline: none;
    }

    & i {
      position: relative;
      display: block;
      transform: translate3d(0, 2px, 0);
    }
  }
`;

const PosedNavLinkA = styled(Text)`
  text-decoration: none;
  line-height: 1;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding-top: 2px;
  height: 1.2em;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${({ underlinecolor }) => underlinecolor};
    height: ${({ thickness }) => thickness}px;
    transform: translate3d(-102%, 0, 0);
    transition: transform 0.2s ease-in;
    will-change: transform;
  }

  :hover,
  :focus {
    outline: none;

    :after {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease-out;
    }
  }
`;

const NavBox = styled(
  posed(PoseableBox)({
    showing: {
      width: `auto`,
      transition: {
        delay: 400,
        type: "tween",
        ease: easings.easeSinOut,
        duration: 200,
      },
    },
    hidden: {
      width: 42,
      transition: {
        delay: 400,
        type: "tween",
        ease: easings.easeSinIn,
        duration: 200,
      },
    },
  })
)`
  position: fixed;
  display: flex;
  padding: 10px 16px;
  z-index: 1001;
  width: 42px;

  ${({ t, r }) =>
    `${cssForBreakpoints("top", t)}${cssForBreakpoints("right", r)}`}
`;

const Burger = styled(
  posed(PoseableButton)({
    showing: {
      x: 1000,
      opacity: 0,
      transition: {
        opacity: {
          type: "tween",
          ease: easings.easeSinIn,
          duration: 200,
          delay: 1,
        },
        x: {
          delay: 201,
          type: "tween",
          duration: 1,
        },
      },
    },
    hidden: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: {
          delay: 500,
          type: "tween",
          ease: easings.easeSinOut,
          duration: 300,
        },
        x: {
          type: "tween",
          duration: 1,
        },
      },
    },
  })
)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  transform: translateX(0) translateZ(0);

  & span {
    display: block;
    position: absolute;
    background-color: white;
    left: 10px;
    top: 14px;
    width: calc(100% - 20px);
    height: 2px;
    will-change: top;
    transition: top 0.2s ease-out;

    &:nth-child(2) {
      top: calc(50%);
    }

    &:nth-child(3) {
      top: calc(100% - 14px);
    }
  }
  :focus {
    transition: box-shadow 0.3s ease-out;
    box-shadow: 0 0 1px 2px ${(props) => props.theme.fgColor};
  }
  :focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
    box-shadow: 0 0 1px 2px transparent;
    transition: box-shadow 0.2s ease-in;
  }

  :hover,
  :focus {
    & span {
      &:nth-child(1) {
        top: 12px;
      }

      &:nth-child(3) {
        top: calc(100% - 12px);
      }
    }
  }
`;

const LogoText = styled(
  posed.span({
    off: {
      opacity: 0,
      applyAtEnd: {
        visibility: `hidden`,
      },
    },
    full: {
      opacity: 0.99999,
      y: 0,
      applyAtStart: {
        visibility: `visible`,
      },
      transition: {
        delay: 150,
        type: `tween`,
        duration: 300,
        ease: easings.easeSinOut,
      },
    },
    tent: {
      opacity: 0,
      y: -35,
      applyAtEnd: {
        visibility: `hidden`,
      },
      transition: {
        type: `tween`,
        duration: 300,
        ease: easings.easeSinIn,
      },
    },
  })
)`
will-change:opacity; transform;
  ${({ fontSize }) => cssForBreakpoints("font-size", fontSize)}
`;

const LogoIcon = styled(
  posed.i({
    off: {
      opacity: 0,
      y: 0,
      applyAtEnd: {
        visibility: `hidden`,
        willChange: `unset`,
      },
      transition: {
        type: `tween`,
        duration: 200,
        ease: easings.easeSinIn,
      },
    },
    full: {
      opacity: 0,
      y: 35,
      applyAtEnd: {
        visibility: `hidden`,
        willChange: `unset`,
      },
      transition: {
        type: `tween`,
        duration: 300,
        ease: easings.easeSinIn,
      },
    },
    tent: {
      opacity: 0.99999,
      y: 0,
      applyAtStart: {
        visibility: `visible`,
        willChange: `transform, opacity`,
      },
      transition: {
        delay: 150,
        type: `tween`,
        duration: 300,
        ease: easings.easeSinOut,
      },
    },
  })
)`
will-change:opacity; transform;
  ${({ fontSize }) => cssForBreakpoints("font-size", fontSize)}
  ${({ top }) => cssForBreakpoints("top", top)}
`;

export default class Nav extends PureComponent {
  static propTypes = {
    mobile: PropTypes.bool,
    theme: PropTypes.object,
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }),
    hasJobPostings: PropTypes.bool,
  };

  lastScrollY = 0;
  state = { showing: true, top: true, burgerOpen: false, location: null };

  onScroll() {
    let state = { top: false, showing: false };

    if (window.__smoothScrollY === 0) {
      state.top = true;
      state.showing = true;
      state.burgerOpen = false;
    }

    this.setState(state, () => {
      if (this.state.showing === true) {
        clearTimeout(this.showingTimeout);
        clearTimeout(this.burgerTimeout);
        this.showingTimeout = setTimeout(() => {
          this.setState({
            showing: window.__smoothScrollY === 0 ? true : false,
            top: window.__smoothScrollY === 0,
          });
        }, 3000);
      }
    });

    this.lastScrollY = window.__smoothScrollY;
  }

  componentDidMount() {
    this.onScroll = this.onScroll.bind(this);
    GlobalEmitter.on(events.scrolled, this.onScroll);
    // window.addEventListener("scroll", this.onScroll, { passive: true });
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.scrolled, this.onScroll);
    window.removeEventListener("scroll", this.onScroll, { passive: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      clearTimeout(this.burgerTimeout);
      this.burgerTimeout = setTimeout(() => {
        this.setState({ burgerOpen: false });
      }, 750);
    }
  }

  openBurger = () => {
    this.setState({ burgerOpen: true }, () => {
      clearTimeout(this.burgerTimeout);
      this.burgerTimeout = setTimeout(() => {
        this.setState({ burgerOpen: false });
      }, 5000);
    });
  };

  render() {
    const { theme, mobile, logoColor, className, location, hasJobPostings } =
      this.props;
    const { top, burgerOpen } = this.state;
    const navLength = navItems.length;

    return (
      <Box as="header" className={className}>
        <LogoLink
          theme={theme}
          t={[200, 145, 145]}
          l={[20, 32, 32, 40]}
          m={0}
          color={logoColor}
        >
          <GatsbyLink to={"/"} title="Home">
            <VisuallyHidden>Camp Jefferson</VisuallyHidden>
            <LogoText
              fontSize={[20, 24]}
              initialPose={mobile && !top ? `off` : `full`}
              pose={
                mobile && !top ? `off`
                : top ?
                  `full`
                : `tent`
              }
            >
              <TextLogo />
            </LogoText>
            <LogoIcon
              initialPose={mobile && !top ? `off` : `full`}
              pose={
                mobile && !top ? `off`
                : top ?
                  `full`
                : `tent`
              }
              fontSize={[24, 36]}
              top={[-7, -7]}
              style={{ visibility: `hidden`, opacity: 0 }}
            >
              <Tent />
            </LogoIcon>
          </GatsbyLink>
        </LogoLink>
        <NavBox
          is="nav"
          t={[190, 135, 135]}
          r={[10, 20, 30, 35, 40]}
          bg={theme.colors.black}
          initialPose={"hidden"}
          pose={burgerOpen ? `showing` : `hidden`}
          id="navigation"
          aria-expanded={burgerOpen}
        >
          <Burger
            id="toggle"
            onClick={this.openBurger}
            aria-label={`Toggle Menu`}
            aria-pressed={burgerOpen}
            aria-controls={`navigation`}
            aria-expanded={burgerOpen}
          >
            <VisuallyHidden>Open navigation</VisuallyHidden>
            <span />
            <span />
            <span />
          </Burger>
          {mobile && !top && (
            <PosedNavLink
              key={`topbarnavlink-logo`}
              initialPose={burgerOpen ? `showing` : `hidden`}
            >
              <Text
                as={GatsbyLink}
                to={"/"}
                title={"Home"}
                fontFamily="Calibre"
                fontWeight="bold"
                color={theme.colors.white}
                tabIndex={burgerOpen ? 0 : -1}
                mr={[15, 20, 25]}
                style={{
                  willChange: `color`,
                  transition: `color 0.3s ease-out`,
                }}
              >
                <i>
                  <Tent />
                </i>
              </Text>
            </PosedNavLink>
          )}
          {navItems.map(({ label, url }, idx) => (
            <PosedNavLink key={`topbarnavlink-${idx}`}>
              <PosedNavLinkA
                as={GatsbyLink}
                to={url}
                title={label}
                fontFamily="Calibre"
                fontWeight="bold"
                color={theme.colors.white}
                underlinecolor={theme.colors.white}
                tabIndex={burgerOpen ? 0 : -1}
                thickness={2}
                mr={idx < navLength - 1 || hasJobPostings ? [16, 20] : 0}
              >
                {label}
              </PosedNavLinkA>
            </PosedNavLink>
          ))}
          {hasJobPostings && (
            <PosedNavLink key={`topbarnavlink-careers`}>
              <PosedNavLinkA
                as={GatsbyLink}
                to={`/contact#careers`}
                title={`Careers`}
                fontFamily="Calibre"
                fontWeight="bold"
                color={theme.colors.white}
                underlinecolor={theme.colors.white}
                thickness={2}
                tabIndex={burgerOpen ? 0 : -1}
                mr={0}
                onClick={() => {
                  if (location.pathname === `/contact`) {
                    const el = document.getElementById(`careers`);
                    if (el) {
                      this.hashTimeout = setTimeout(() => {
                        GlobalEmitter.emit(events.scrollIntoView, { el });
                      }, 10);
                    }
                  }
                }}
              >
                {`Careers`}
              </PosedNavLinkA>
            </PosedNavLink>
          )}
        </NavBox>
      </Box>
    );
  }
}
