import React from "react";
import { Box, Heading, Text } from "rebass";
import { Container } from "./layout";
export const Banner = () => {
  return (
    <Box
      bg="black"
      color="white"
      py={20}
      style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 9999 }}
    >
      <Container textAlign="center" style={{ position: "unset" }}>
        <Heading
          is="h3"
          fontFamily="Druk"
          fontSize={[4, 5]}
          textAlign="center"
          style={{ position: "unset" }}
        >
          Camp Jefferson has merged with Jungle Media and is now{" "}
          <a
            href="https://www.mekanism.com"
            target="_blank"
            rel="noopener noreferrer"
            className="banner-link"
          >
            Mekanism Canada
          </a>
          .
        </Heading>
        <Text textAlign="center" fontSize={16} mt={4}>
          More creativity, more media power, more integration, and more results.
        </Text>
      </Container>
    </Box>
  );
};
