import AnimatedGrid from "components/AnimatedGrid";
import BGGrid from "components/BGGrid";
import BreakpointListener from "components/BreakpointListener";
import Nav from "components/Nav";
import { PoseableBox } from "components/poseable";
import React, { Fragment, PureComponent } from "react";
import Helmet from "react-helmet";
import posed, { PoseGroup } from "react-pose";
import Scrollbar from "react-smooth-scrollbar";
import SmoothScrollbar from "smooth-scrollbar";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme, {
  blackTheme,
  blackYellowTheme,
  blueTheme,
  greenTheme,
} from "theme";
import { globals, minireset } from "theme/globalStyles";
import DisableScrollPlugin from "utils/DisableScrollPlugin";
import events from "utils/events";
import getOffset from "utils/getOffset";
import GlobalEmitter from "utils/GlobalEmitter";
import { Banner } from "./Banner";
import PageLoader from "./page-loader/PageLoader";

SmoothScrollbar.use(DisableScrollPlugin);

const GlobalStyle = createGlobalStyle`
    ${minireset}
    ${globals}
`;

const Transition = posed(PoseableBox)({
  siteEnter: {
    visibility: `visible`,
  },
  pageEnter: {
    applyAtStart: {
      zIndex: 0,
      visibility: `hidden`,
    },
    applyAtEnd: {
      visibility: `visible`,
    },
    zIndex: 1,
    transition: ({ first }) => ({
      delay: first ? 0 : 500,
    }),
  },
  pageExit: {
    zIndex: 1,
    applyAtEnd: {
      zIndex: 0,
    },
    transition: {
      duration: 1,
      type: `tween`,
      delay: 500,
    },
  },
});

const getMobileLogoColor = (pathname) => {
  switch (true) {
    case pathname.indexOf("cases/") >= 0:
      return `white`;
    default:
      return null;
  }
};

const getCurrentTheme = (pathname) => {
  switch (true) {
    case pathname === "agency":
      return blackTheme;
    case pathname === "training-camp":
    case pathname.indexOf("splurge") >= 0:
    case pathname.indexOf("perspectives") >= 0:
    case pathname.indexOf("events/") >= 0:
      return blackYellowTheme;
    case pathname === "contact":
      return greenTheme;
    case pathname === "work":
    case pathname.indexOf("cases/") >= 0:
      return blueTheme;
    case pathname.indexOf("jobs/") >= 0:
    case pathname.indexOf("intake") >= 0:
      return blackTheme;
    default:
      return theme;
  }
};

const getNormalizedPathname = (pathname) => {
  if (pathname.charAt(0) === "/") {
    pathname = pathname.substr(1);
  }
  if (pathname.charAt(pathname.length - 1) === "/") {
    pathname = pathname.substr(0, pathname.length - 1);
  }
  return pathname;
};

export default class TemplateWrapper extends PureComponent {
  static IS_TRANSITIONING = false;
  newScrollY = 0;
  scrollY = 0;

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const normalizedPathname = getNormalizedPathname(pathname);
    if (normalizedPathname !== prevState.pathname) {
      return {
        pathname: normalizedPathname,
        showing: true,
        mobileLogoColor: getMobileLogoColor(normalizedPathname),
        animationTheme: getCurrentTheme(normalizedPathname),
      };
    }
    return {
      mobileLogoColor: getMobileLogoColor(normalizedPathname),
    };
  }

  constructor(props) {
    super(props);
    let pathname = getNormalizedPathname(props.location.pathname);
    // this.addWheelListener = this.addWheelListener.bind(this);
    this.state = {
      mobile: false,
      mobileNav: false,
      rendered: false,
      pathname,
      animationTheme: getCurrentTheme(pathname),
      pageTheme: getCurrentTheme(pathname),
      showing: true,
    };
  }

  scrollTo({ y, duration = 600 }) {
    // console.log("scrollTo", { y, duration });
    this.scrollbar.scrollTo(0, -y, duration);
  }

  resizeScrollbar() {
    this.scrollbar.update();
  }

  scrollIntoView({ el, offset = 0, duration = 600, centered = false }) {
    const { top, height } = getOffset(el);
    if (centered) {
      offset -= window.innerHeight * 0.5 - height * 0.5;
    }
    this.scrollbar.scrollTo(0, top + offset, duration);
  }

  componentDidMount() {
    this.onScroll = this.onScroll.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.onScrollDisable = this.onScrollDisable.bind(this);
    this.onScrollEnable = this.onScrollEnable.bind(this);
    this.resizeScrollbar = this.resizeScrollbar.bind(this);
    GlobalEmitter.on(events.pageReady, this.onPageReady);
    GlobalEmitter.on(events.scrollTo, this.scrollTo);
    GlobalEmitter.on(events.scrollIntoView, this.scrollIntoView);
    GlobalEmitter.on(events.disableScroll, this.onScrollDisable);
    GlobalEmitter.on(events.enableScroll, this.onScrollEnable);
    GlobalEmitter.on(events.resizeScrollbar, this.resizeScrollbar);
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);

    this.setState({
      mobile: BreakpointListener.size < 2,
      mobileNav: BreakpointListener.size < 2,
    });

    this.renderTimeout = setTimeout(() => {
      this.setState({ rendered: true, showing: false });
    }, 1000);

    this.scrollbar = this.$scrollbarContainer.scrollbar;
    this.contentRef = document.getElementById("main");
    this.scroller = document.querySelector(`[data-scrollbar]`);

    this.scroller.style.height = `100vh`;
    this.scroller.style.width = `100vw`;
    this.scroller.style.overflowX = `hidden`;
    document.body.style.overflow = `hidden`;
    document.body.style.height = `100vh`;

    window.dynamicScrollTo = (idOrEl) => {
      const el =
        typeof idOrEl == "string" ? document.getElementById(idOrEl) : idOrEl;
      GlobalEmitter.emit(events.scrollIntoView, {
        el,
        offset: -25,
        duration: 600,
        centered: false,
      });
    };
  }

  componentWillUnmount() {
    GlobalEmitter.off(events.pageReady, this.onPageReady);
    GlobalEmitter.off(events.scrollTo, this.scrollTo);
    GlobalEmitter.off(events.scrollIntoView, this.scrollIntoView);
    GlobalEmitter.off(events.resizeScrollbar, this.resizeScrollbar);
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    clearTimeout(this.renderTimeout);
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2, mobileNav: newSize < 3 });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pathname !== prevState.pathname) {
      TemplateWrapper.IS_TRANSITIONING = true;
      clearTimeout(this.hashTimeout);
    }
  }

  onPageReady = () => {
    this.setState({
      showing: false,
      pageTheme: getCurrentTheme(this.state.pathname),
    });
  };

  onScroll(e) {
    window.__smoothScrollY = e.offset.y;
    GlobalEmitter.emit(events.scrolled, e);
  }

  onScrollEnable() {
    this.scrollbar.updatePluginOptions("disable", { disabled: false });
  }

  onScrollDisable() {
    this.scrollbar.updatePluginOptions("disable", { disabled: true });
  }

  onTransitionPoseComplete = (pose) => {
    switch (pose) {
      case "pageExit":
        // window.scrollTo({ top: 0, left: 0 });
        this.scrollbar.scrollTo(0, 0);
        GlobalEmitter.emit(events.pageExit);
        break;
      case "pageEnter":
        TemplateWrapper.IS_TRANSITIONING = false;
        if (this.props.location.hash) {
          const id = this.props.location.hash.substr(1);
          const el = document.getElementById(id);
          if (el) {
            this.hashTimeout = setTimeout(() => {
              // window.scroll({
              //   top: el.getBoundingClientRect().top + window.pageYOffset,
              //   left: 0,
              //   behavior: "smooth",
              // });
              this.scrollbar.scrollTo(0, el.getBoundingClientRect().top, 600);
            }, 500);
          }
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { children, location, pageContext } = this.props;
    const { hasJobPostings } = pageContext;
    const {
      pageTheme,
      animationTheme,
      mobile,
      mobileNav,
      pathname,
      showing,
      rendered,
      mobileLogoColor,
    } = this.state;
    return (
      <Fragment>
        <Helmet />
        <GlobalStyle />
        <ThemeProvider theme={pageTheme}>
          <Fragment>
            <BreakpointListener theme={theme} />
            <Banner />
            <Nav
              hasJobPostings={hasJobPostings}
              mobile={mobileNav}
              theme={animationTheme}
              location={location}
              logoColor={
                mobile && mobileLogoColor ? mobileLogoColor
                : (
                  pathname.indexOf("splurge") >= 0 ||
                  pathname.indexOf("perspectives") >= 0
                ) ?
                  theme.colors.black
                : pageTheme.textColor
              }
              css={`
                & * {
                  ::selection {
                    background: ${pageTheme.revealColor};
                  }
                }
              `}
            />
            <Scrollbar
              ref={(c) => (this.$scrollbarContainer = c)}
              damping={0.1}
              thumbMinSize={60}
              renderByPixels={false}
              alwaysShowTracks={false}
              onScroll={this.onScroll}
            >
              <BGGrid
                mobile={mobile}
                bgColor={pageTheme.bgColor}
                lineColor={pageTheme.lineColor}
                height={`100%`}
              />
              <PoseGroup
                key="__pagecontainer"
                animateOnMount={false}
                flipMove={false}
                preEnterPose={this.first ? "siteEnter" : "prePageEnter"}
                enterPose="pageEnter"
                exitPose="pageExit"
                enterAfterExit={true}
              >
                <Transition
                  id="main"
                  is="main"
                  role="main"
                  key={pathname}
                  pt={[80, 100, 125, 150]}
                  first={this.first}
                  onPoseComplete={this.onTransitionPoseComplete}
                  style={{
                    visibility: rendered ? `visible` : `hidden`,
                    position: `relative`,
                    backfaceVisibility: `hidden`,
                  }}
                  css={`
                    & * {
                      ::selection {
                        background: ${pageTheme.revealColor};
                      }
                    }
                  `}
                >
                  {children}
                </Transition>
              </PoseGroup>
            </Scrollbar>
            <div id="docked-video-container" />
            <AnimatedGrid
              first={rendered ? false : true}
              showing={showing}
              mobile={mobile}
              bgColor={animationTheme.bgColor}
              z={1000}
            />
            <PageLoader theme={animationTheme} showing={showing} />
          </Fragment>
        </ThemeProvider>
      </Fragment>
    );
  }
}
